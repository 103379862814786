import React from "react";

function ButtonToStore() {
  return (
    <a href="/store" className="btn btn--white btn--animated">
      Conheça nossos produtos
    </a>
  );
}

export default ButtonToStore;
