import React from "react";
import ReactDOM from "react-dom";
import "./sass/main.scss";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
// import { Auth0Provider } from "@auth0/auth0-react";
import { createStore, applyMiddleware } from "redux";
import { Provider } from "react-redux";
import thunk from "redux-thunk";
import { reducers } from "./reducers";

const store = createStore(reducers, applyMiddleware(thunk));

ReactDOM.render(
  // <Auth0Provider
  //   domain="meninasdoceiras.us.auth0.com"
  //   subdomain="meninasdoceiras.us"
  //   clientId="iUH4RcI1uEyzcOdUL2yC3c0Esij8TSgm"
  //   redirectUri={window.location.origin}
  // >
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  // </Auth0Provider>,

  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
